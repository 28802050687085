<template>
  <section class="section p-0 m-0">
    <div class="p-landing">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div
              class="c-landing-tagline justify-content-start align-items-center mb-4"
            >
              <h1 class="main-heading text-center mb-4">
                VISUALISASI DATA <span>JAWA BARAT</span>
              </h1>
              <p
                class="c-landing-subtitle mb-0 primary-font text-center font-weight-normal"
              >
                Selamat Datang,
              </p>
              <p
                class="c-landing-subtitle mb-0 primary-font text-center font-weight-bold"
              >
                {{ this.$store.getters.getUser.name }}
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-8 mx-auto">
            <div class="row mb-4">
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--green"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-1.svg"
                        alt=""
                        width="60"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      KEUANGAN DAERAH
                    </h6>
                    <p class="text-center">
                      Informasi penyerapan <br />
                      keuangan daerah <br />
                      Provinsi Jawa Barat <br />
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--blue"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-2.svg"
                        alt=""
                        width="65"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      KEPEGAWAIAN
                    </h6>
                    <p class="text-center">
                      Informasi mengenai <br />
                      Kepegawaian <br />
                      Provinsi Jawa Barat <br />
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--yellow"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-3.svg"
                        alt=""
                        width="62"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      INFRASTRUKTUR
                    </h6>
                    <p class="text-center">
                      Progress dan Informasi <br />
                      perkembangan pembangunan <br />
                      Infrastruktur <br />
                      Provinsi Jawa Barat
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--green"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-4.svg"
                        alt=""
                        width="35"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      KESEHATAN
                    </h6>
                    <p class="text-center">
                      Informasi mengenai <br />
                      Progam Kesehatan <br />
                      Provinsi Jawa Barat <br />
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--blue"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-5.svg"
                        alt=""
                        width="50"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      PENDIDIKAN
                    </h6>
                    <p class="text-center">
                      Informasi mengenai <br />
                      Perkembangan Pendidikan <br />
                      Provinsi Jawa Barat <br />
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-md-4">
                <a
                  href="#"
                  class="landing-menu bg-white rounded border card-dashboard card-dashboard--yellow"
                >
                  <div
                    class="px-1 pt-4 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <div class="landing-menu-image">
                      <img
                        src="@/assets/img/landing/menu-6.svg"
                        alt=""
                        width="60"
                      />
                    </div>
                    <h6 class="text-center mb-4">
                      DESA
                    </h6>
                    <p class="text-center">
                      Informasi mengenai <br />
                      Program - program <br />
                      pengembangan Desa <br />
                      Provinsi Jawa Barat
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
